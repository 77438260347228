import React from 'react';


class FabricIndexTabPanelEndpoint extends React.Component {

    constructor(props) {
        super(props);
    }




    render () {

        const style_panel_heading = {
            height: '30px',
            color: '#f1f1f1'
        };
        const ice_cream_panel_heading = {
            color: 'white',
            paddingRight: '5px'
        };
        const ice_cream_panel_body = {
            color: '#337ab7'
        };
        const h5_style = {
            marginTop: 'unset'
        };

        var chaincodes
        if (this.props.endpoint.Chaincodes) {
            chaincodes = this.props.endpoint.Chaincodes.map((cc, index) => {
                return (
                    <li key={index}><span>{cc}</span>  </li>
                )
            });
        }
        else {
            chaincodes = <li><span>{"Chaincode is not installed."}</span>  </li>
        }


        

        return (
                <div className="panel-group">
                    <div className="panel panel-primary">
                        <div className="panel-heading" style={style_panel_heading}>
                            <h5 style={h5_style} ><b>
                            <i className="fas fa-ice-cream" style={ice_cream_panel_heading} ></i>
                            <span>MSPID: {this.props.endpoint.MSPID}</span>
                            </b></h5>
                        </div>
                        <div className="panel-body">
                            <ul className="fa-ul">
                                <li><span className="fa-li"><i className="fas fa-ice-cream" style={ice_cream_panel_body}></i></span><span>Endpoint: {this.props.endpoint.Endpoint}</span></li>
                                <li><span className="fa-li"><i className="fas fa-ice-cream" style={ice_cream_panel_body}></i></span><span>LedgerHeight: {this.props.endpoint.LedgerHeight}</span></li>                                        
                                <li><span className="fa-li"><i className="fas fa-ice-cream" style={ice_cream_panel_body}></i></span>  
                                    <span>Chaincodes: </span>
                                    <ul>
                                        {chaincodes}
                                    </ul>                                              
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        )
    }
}

export default FabricIndexTabPanelEndpoint