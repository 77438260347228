import React, {forwardRef} from "react";
import Refills from "./Refills";
import {getSuggestions, renderSuggestion, getSuggestionValue, renderSuggestionsContainer} from "./AutoSuggest";
import Autosuggest from 'react-autosuggest';

//calendario
import DatePicker, { registerLocale } from "react-datepicker";


class Machines extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        msg: "",
        refills: [],
        // start/end_refill_ts
        selectRefillDayInSeconds: "",
        selectRefillDayInSecondsEnd: "0",
        // search refills button
        selectMachine: "",
        //selectChannel: "",
        searchDisabled: false,
        // pagination
        disabledNext: true,
        pageNumber: 1,
        pageSize: 20, //saranno 20 in partenza
        // suggestions
        suggestions: [],
        suggestionsChannels: [],
        query: "",        // choose a machine
        queryChannel: "", // choose a channel: aggiornabile manualmente o in base al query (search channel button)
        always_ender_uggestions: true, //bool per far sparire il suggest dalla barra di ricerca delle macchine
        // calendar
        startDate: new Date(),
        endDate: new Date(),
        today: this.inSeconds(new Date().setHours(23,59,59,59))
    };
    this.getRefills= this.getRefills.bind(this);
    this.getRefills_sdk_fabric= this.getRefills_sdk_fabric.bind(this);
    this.selectChangedMachineAndChannel = this.selectChangedMachineAndChannel.bind(this);
    this.selectChangedRefill = this.selectChangedRefill.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getAllNames = this.getAllNames.bind(this);
    this.onChangeSuggest = this.onChangeSuggest.bind(this);
    this.onChangeSuggestChannel = this.onChangeSuggestChannel.bind(this);
    this.setAllSuggestions = this.setAllSuggestions.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
    //this.getChannelofMachine = this.getChannelofMachine.bind(this);
  }

  UNSAFE_componentWillMount() {
    /*console.log("(today)", this.state.today);
    console.log("new Date(today)", new Date(this.state.today));
    */
    //setto la macchina da visualizzare e l'ultima pagina e query per visualizzarlo in autosuggest
    this.setState({selectMachine: this.props.selectMachine, query: this.props.selectMachine });//, (e) => {this.getLastPage(1,e)});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.selectMachine != this.state.selectMachine || prevState.selectRefillDayInSeconds != this.state.selectRefillDayInSeconds || prevState.selectRefillDayInSecondsEnd != this.state.selectRefillDayInSecondsEnd  || prevState.query != this.state.query){
        this.setState({refills: [], disabledNext: false, searchDisabled: false});//this.getLastPage(1);});
      }
      if (this.state.pageNumber <=1){
        this.setState({disabledNext: true});
      }
      /*if (this.state.query != prevState.query){
          this.setState({query: newValue}, () => console.log(""));
      }*/

   }



    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.selectRefillDayInSeconds != nextState.selectRefillDayInSeconds){
            return true;
        }
        if (this.state.selectRefillDayInSecondsEnd != nextState.selectRefillDayInSecondsEnd){
            return true;
        }
        if (this.state.selectMachine!= nextState.selectMachine){
            return true;
        }
        /*if (this.state.selectChannel!= nextState.selectChannel){
            return true;
        }*/
        if (this.state.refills != nextState.refills){
            return true;
        }
        if(this.state.disabledNext != nextState.disabledNext){
            return true;
        }
        return false;
    }


    // ----------------- AUTO_SUGGEST ----------------
    onChangeSuggest(event, { newValue }){ //Machines
        this.setState({
            query: newValue,
            searchDisabled: false
        }, () => this.forceUpdate());
    };

    onChangeSuggestChannel(event, { newValue }){ //Channels
        this.setState({
            queryChannel: newValue,
            searchDisabled: false
        }, () => this.forceUpdate());
    };

    getAllNames(){
        var machinesName = this.props.machines.map( (machine, index) => {
            return machine.name;//.split('.')[0].split('=')[1];
        });
        this.setState({machinesNames: machinesName}, () => console.log())
    }


    onSuggestionsFetchRequested({ value }){
        if (value != ""){
            this.setState({
                suggestions: getSuggestions(value, this.props.machinesNames)
            }, () => this.forceUpdate());
        }
        else {
            this.setState({
                suggestions: this.props.machinesNames
            }, () => this.forceUpdate());
        }
    };

    onSuggestionsClearRequested(){
        this.setState({
            suggestions: []//this.props.machinesNames
        }, () => this.forceUpdate());
    };

    setAllSuggestions(){
        if (this.state.suggestions.length == 0){
             // tutte
            this.setState({
                suggestions: this.props.machinesNames
            }, () => this.forceUpdate());
        }
        else{
            this.onSuggestionsClearRequested(); // clear
        }
    }

    shouldRenderSuggestions(){
        return true;
    }

    handleFocus = (event) => event.target.select();

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
        this.input = autosuggest.input;
        }
    }

    




    //--------------------------- Calendario --------------------
    // get data from timestamp
    getDateFromTs(timestamp){
        if (timestamp.length == 10){
            timestamp = timestamp+"000";
        }
        var date = new Date( parseInt(timestamp));
        return date;
    }


    inSeconds(date){
        return Math.floor(date/1000)
    }

    setStartDate(date) {
        var today = this.state.today

        var dayCalendarSelected = this.inSeconds(date.date.setHours(0,0,0,0));
        //console.log("setStartDate\ntoday", today, "\ndaySelected", dayCalendarSelected);
        var diffDaysInSeconds = (today-dayCalendarSelected); //in seconds
        //console.log("diffDaysInSeconds:",diffDaysInSeconds)

        this.setState({
          startDate: date.date,
          selectRefillDayInSeconds: diffDaysInSeconds.toString()
        }, () => { 
            //console.log("selectRefillDayInSeconds:",this.state.selectRefillDayInSeconds,"\nstartDate:",this.state.startDate);
            this.forceUpdate();
        } );
    };


    /*setStartDateFromRefillTimestamp(ts) {
        var startRefillDate = this.getDateFromTs(ts);
        console.log("startTS: ",ts,"startRefillDate:",startRefillDate);
        this.setState({
          startDate: startRefillDate
        }, () => { 
            console.log("setStartDateFromRefillTimestamp:",this.state.startDate);
            this.forceUpdate();
        } );        
    };*/


    setEndDate(date) {
        var today = this.state.today
        var dayCalendarSelected = this.inSeconds(date.date.setHours(23,59,59,59));
        var diffDaysInSeconds = (today-dayCalendarSelected);

        this.setState({
            endDate: date.date,
            selectRefillDayInSecondsEnd: diffDaysInSeconds.toString()
        }, () => {
            //console.log("selectRefillDayInSecondsEnd:",this.state.selectRefillDayInSecondsEnd,"\endDate:",this.state.endDate); 
            this.forceUpdate()});        
    };


    /*setEndDateFromRefillTimestamp(ts) {
        var endRefillData = this.getDateFromTs(ts);
        console.log("endTS: ",ts,"endRefillDate:",endRefillData);
        this.setState({
          endDate: endRefillData
        }, () => { 
            console.log("setEndDateFromRefillTimestamp:",this.state.endDate);
            this.forceUpdate();
        } );        
    };*/









    onChangePage() {
        this.setState({pageNumber: this.state.pageNumber -=1}, () => this.getRefills());
        //this.setState({pageNumber: this.state.pageNumber -=1}, () => this.getRefills_sdk_fabric());
    }

    selectChangedMachineAndChannel(e){
        //se i valori non sono cambiati allora non faccio nulla
        //if (this.state.selectMachine != this.state.query || this.state.selectChannel != this.state.queryChannel){
            //controòllo che la macchina e canale li posso vedere
        if (this.props.machinesNames.some(item => this.state.query === item)){ // && this.props.channelsNames.some(item => this.state.queryChannel === item)){
            this.setState({
                refills: [],
                selectMachine: this.state.query, 
                //selectChannel: this.state.queryChannel,
                searchDisabled: true
            //},() => this.getRefills_sdk_fabric());
            },() => this.getRefills());
        }
        else {
            this.setState({
                refills: [],
                msg: "Machine is wrong.",
                searchDisabled: true
            },() => this.forceUpdate());
        }
        //}
    }



    selectChangedRefill(e){
        this.setState({ selectRefillDayInSeconds: e.target.value, selectRefillDayInSecondsEnd: "0", startDate: new Date(), endDate: new Date()},() => this.forceUpdate());
    }



  /***** QUANDO CI SARÀ IMPAGINAIZONE ************
  getLastPage(pageNumber){ //ottengo ultima pagina dell'impagination e faccio partire il pageNumber dall'ultimo a ritroso
      //event.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append('X-CSRF-Token', Rails.csrfToken());
      myHeaders.append('Content-Type', 'application/json');

      const options = {
          method: 'POST',
          headers: myHeaders,
          credentials: 'same-origin',
          body: JSON.stringify({
              "machine": this.state.selectMachine,
              'timestampRefill': this.state.selectRefillDayInSeconds,
              'pageSize': this.state.pageSize.toString(),
              'pageNumber': pageNumber.toString(),
            'tsRefillEnd': this.state.selectRefillDayInSecondsEnd
          })
      };

      const request = new Request('/get_txs', options);

        fetch(request)
            .then(results => results.json())
            .then(results => {
                  if(results.length == this.state.pageSize){
                      this.getLastPage(pageNumber+1)
                  } else {
                      if (results.length == 0){
                          this.setState({pageNumber: pageNumber-1, disabledNext: false, refills: []}, function () {
                            this.forceUpdate();
                            this.getRefills();
                          });
                      } else {
                          this.setState({pageNumber: pageNumber, disabledNext: false, refills: []}, function () {
                            this.forceUpdate();
                            this.getRefills();
                          });
                      }
                  }
            })
            .catch((e) => console.log())
  }
  */




  getRefills(){
    var myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', Rails.csrfToken());
    myHeaders.append('Content-Type', 'application/json');

    var today = this.state.today; //new Date();
    // selectRefillDayInSeconds -> [0, 1, 7, 30] -> numero per indicare quanti giorni fa
    // stesso selectRefillDayInSecondsEnd
    var start_refill_ts="";
    if (this.state.selectRefillDayInSeconds != ""){
        //console.log("Aggiorno selectRefillDayInSeconds:", this.state.selectRefillDayInSeconds)
        start_refill_ts = (today - this.state.selectRefillDayInSeconds); // data = oggi - selectRefillDayInSeconds (x giorni fa)
    }
    var end_refill_ts = (today - this.state.selectRefillDayInSecondsEnd); 
    /*console.log("this.state.selectRefillDayInSeconds",this.state.selectRefillDayInSeconds);
    console.log("this.state.selectRefillDayInSecondsEnd",this.state.selectRefillDayInSecondsEnd);
    console.log("start_refill_ts:",start_refill_ts);
    console.log("end_refill_ts:",end_refill_ts);
    */
    
    const options = {
        method: 'POST',
        headers: myHeaders,
        credentials: 'same-origin',
        body: JSON.stringify({
            "machine_name": this.state.selectMachine,
            //"channel_name": this.state.selectChannel,
            // REFILL FILTERING
            'start_refill_ts': start_refill_ts,
            'end_refill_ts': end_refill_ts
            /* IMPAGINATION
            'pageSize': this.state.pageSize.toString(),
            'pageNumber': this.state.pageNumber.toString(),*/
        })
    };

    const request = new Request('/fabric/get_fabric_txs', options);

    fetch(request)
        .then(results => results.json())
        .then(results => {
            //console.log("secondo then results già in jso0n: ", results);
            if (results.message != "" &&  results.message ){
                //console.log("MSG: ", results.message)
                this.setState({msg: results.message}, () => this.forceUpdate());
            }
            else if(results.length > 0) {
                //console.log("results.length > 0")
                //console.log("Aggiorno i refills in state")
                this.setState({
                    disabledNext: false,
                    msg: "",
                    refills: this.state.refills.concat(results)
                }, function () {
                    //console.log("Results:",results);
                    //this.forceUpdate();
                    
                    /*
                    this.setStartDateFromRefillTimestamp(results.slice(-1)[0].Value.refillTimestamp);
                    this.setEndDateFromRefillTimestamp(results[0].Value.refillTimestamp);
                    */
                });
            }
            else {
                this.setState({msg: "Error ELSE"}, () => this.forceUpdate());
            }
        })
        .catch((error) => {this.setState({msg: "There was a problem. Please contact Carpigiani for assistance."}, () => this.forceUpdate()); })
  }






  /******************** Node js server api */
  getRefills_sdk_fabric(){
    var myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', Rails.csrfToken());
    myHeaders.append('Content-Type', 'application/json');

    var today = this.state.today; //new Date();
    // selectRefillDayInSeconds -> [0, 1, 7, 30] -> numero per indicare quanti giorni fa
    // valo lo stesso per "selectRefillDayInSecondsEnd"
    var start_refill_ts="1500000000";
    if (this.state.selectRefillDayInSeconds != ""){
        start_refill_ts = (today - this.state.selectRefillDayInSeconds); // data = oggi - selectRefillDayInSeconds (x giorni fa)
    }
    var end_refill_ts = (today - this.state.selectRefillDayInSecondsEnd);
    
    const options = {
        method: 'POST',
        headers: myHeaders,
        credentials: 'same-origin',
        body: JSON.stringify({
            "machine_name": this.state.selectMachine,
            'start_refill_ts': start_refill_ts,
            'end_refill_ts': end_refill_ts
        })
    };
    
    //const url = 'http://localhost:5000/getAllTxMachineByModule?machine_name='+this.state.selectMachine+'&channel_name=car-pra-cl1&start_refill_ts='+start_refill_ts+'&end_refill_ts='+end_refill_ts;
    const request = new Request("/fabric/get_sdk_fabric_txs", options);

    fetch(request)
        .then(results => results.json())
        .then(results => {
            console.log("secondo then results già in jso0n: ", results);
            console.log("txs: ", results);
            if (results.message && results.message != ""){
                this.setState({msg: results.message}, () => this.forceUpdate());
            }
            else if(results && results.length > 0) {
                console.log("Aggiorno i refills in state",this.state.refills.concat(results) )
                this.setState({
                    disabledNext: false,
                    msg: "",
                    refills: this.state.refills.concat(results)
                });
            }
            else {
                throw new Error('Error in get refills sdk fabric.');
            }
        })
        .catch((error) => {this.setState({msg: "There was a problem. Please contact Carpigiani for assistance.\n"+error}, () => this.forceUpdate()); })
  }




  render(){

    //console.log("Render machines refills: ",this.state.refills);

    // suggestions, machines
    const suggestions = this.state.suggestions;
    const inputProps = {
        placeholder:"Search a machine (SLS..., M10..., IC...)",
        value: this.state.query,
        onChange: this.onChangeSuggest,
        onKeyDown: this.handleKeyPress,
        type: "search",
        pattern: "[a-zA-Zàèéìòù0-9., ]*",
        title: "Special character are not permitted.",
        onFocus: () => this.props.openOnFocus &&
          this.setState({
            suggestions: this.getSuggestions((this.props.suggestionList[0].label.charAt(0))),
          })
    };


    // suggestions, channels
    /* RELAZIONE MACCHINA CANALE È 1:M -> BASTA NOME MACCHINA PER AVERE CANALE
    const suggestionsChannels = this.state.suggestionsChannels;
    const inputPropsChannels = {
        placeholder:"Search an agreement",
        value: this.state.queryChannel,
        onChange: this.onChangeSuggestChannel,
        type: "search",
        pattern: "[a-z][a-z0-9.-]*",
        title: "Sono vietati i caratteri speciali. Il nome inizia per una lettera. Solo lettere minuscole, cifre, '.' e il '-' sono permessi.",
    };*/


    var buttonNext;
    if(this.state.pageNumber !== 0 && !this.state.disabledNext){
        buttonNext= <button className='btn btn-primary is-rounded btn-lg is-info' onClick={this.onChangePage.bind(this)} id={"avoidPdf"}>
            <span> Next Refills </span> </button>
    }
    else{
        buttonNext=<button className='btn btn-outline-primary btn-lg is-rounded is-info disabled' id={"avoidPdf"}>Next Refills</button>
    }

    const centerItems = {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
    };

    const stylePaddingLeft={
        paddingLeft: "50px"
    };
    const stylePaddingUp={
        paddingTop: "22px",
        // paddingRight: "22px",
        float: 'right',
    };

    const styleBorderRadius={
        borderRadius: '5px'
    };

    //---------- -calendario     ----------------
    var startDate=this.state.startDate;
    var endDate=this.state.endDate;

    // DatePicker
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="input-group date" style={{textAlign: "right"}}>
            <input type="text" className="form-control" style={styleBorderRadius} onClick={onClick} ref={ref} defaultValue={value} />
        </div>
    ));


    // Autosuggest
    /*const renderInputComponent = inputProps => (
        <div>
          <input {...inputProps} onFocus={this.handleFocus} onClick={this.setAllSuggestions} />
        </div>
    );*/


    /********************** DA METTERE SE MACCHINA CANALE HA RELAZIONE N:M 
     * *******************  CANALE SELEZIONABILE A MANO
     * 
     * 
                        <div className="col-sm-4">
                            <label htmlFor="channels" className="control-label">Choose an agreement</label>
                            <Autosuggest
                                suggestions={suggestionsChannels}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={ inputPropsChannels }
                            />
                        </div>
     * 
     * 
     *  ********************/


    return (
        <section>

            <div className="row" style={centerItems}>
                <div className="col-md-8">

                    <div className="row">
                        <div className="col-md-12">

                            <div className="col-md-6" style={{paddingLeft: '0px'}}>
                                <label htmlFor="timestampRefill" className="control-label">Choose (or use the calendar) </label>
                                <select className="form-control" name="timestampRefill" id="timestampRefill" onChange={this.selectChangedRefill.bind(this)} defaultValue={this.state.selectRefillDayInSeconds}>
                                    <option value=""  disabled hidden>Choose days</option>
                                    <option value="">All</option>
                                    <option value="86400">Today</option>
                                    <option value="604800">Week</option>
                                    <option value="2592000">Month</option>
                                </select>
                            </div>
                            <div className="col-md-3" style={{paddingRight: '1px'}}>
                                <label htmlFor="calendar" className="control-label">Start calendar</label>                            
                                <DatePicker
                                    selected={startDate}
                                    onChange={date=>this.setStartDate({date})}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date('01/01/2019')}
                                    maxDate={new Date()}
                                    customInput={<CustomInput />}                                        
                                    //select Month Year dropdown
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    //non chiudere al click del giorno
                                    shouldCloseOnSelect={false}
                                    //classNames
                                    className={"width_fontsize_calendar"}
                                    calendarClassName={"width_fontsize_calendar"}
                                    //locale={it} //sembra non andare
                                />
                            </div>
                            
                            <div className="col-md-3" style={{paddingRight: '0px', textAlign: 'right'}}>
                                <label htmlFor="calendar" className="control-label">End calendar</label>                            
                                <DatePicker
                                    selected={endDate}
                                    onChange={date=>this.setEndDate({date})}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date(startDate)}
                                    maxDate={new Date()}
                                    customInput={<CustomInput />}                                        
                                    //select Month Year dropdown
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    //non chiudere al click del giorno
                                    shouldCloseOnSelect={false}
                                    //classNames
                                    className={"width_fontsize_calendar"}
                                    calendarClassName={"width_fontsize_calendar"}
                                    // locale={it} //sembra non andare
                                />
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div className="row">
                        <div className="col-md-8">
                            <label htmlFor="machine" className="control-label">Choose a machine</label>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={ inputProps }
                                highlightFirstSuggestion={true}
                                //renderSuggestionsContainer={renderSuggestionsContainer} //scroll (da vedere)
                                //renderInputComponent={renderInputComponent} //input custom
                                shouldRenderSuggestions={this.shouldRenderSuggestions}
                                ref={this.storeInputReference}
                                //alwaysRenderSuggestions={this.state.always_ender_uggestions} //rimane la tendina con item scelto

                                //focusInputOnSuggestionClick={!isMobile} // da capire
                            />
                        </div>
                        <div className="col-md-4" >
                            <div  style={stylePaddingUp}>
                                <button className={'btn btn-info is-rounded btn-md '} /*disabled={this.state.searchDisabled}*/ onClick={this.selectChangedMachineAndChannel.bind(this)}>Search refills</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <br/>

            <Refills  {...this.props} refills={this.state.refills} msg={this.state.msg} startDate={this.state.startDate} endDate={this.state.endDate} />
            

        </section>

    )

  }
}

export default Machines
