import React from 'react'

class RelationshipsMachineChannel extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            selectChannel: '',
            selectMachine: '',
            message: '',
            colorFont: ''
        };

        this.selectedChannel = this.selectedChannel.bind(this);
        this.selectedMachine = this.selectedMachine.bind(this);
        this.createRelationship = this.createRelationship.bind(this);
        this.deleteRelationship = this.deleteRelationship.bind(this)

    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextState.selectChannel != this.state.selectChannel)
            return true;
        if(nextState.selectMachine != this.state.selectMachine)
            return true;
        if(nextState.message != this.state.message )
            return true;
        return false;
    }


    selectedChannel(e){
        this.setState({selectChannel: e.target.value})
    }

    selectedMachine(e){
        console.log("e:",e);
        console.log("e.target.value:",e.target.value);
        this.setState({selectMachine: e.target.value}, () => this.forceUpdate())
        //() => this.forceUpdate()
    }

    createRelationship(e){
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append('X-CSRF-Token', Rails.csrfToken());

        const options = {
            method: 'POST',
            headers: myHeaders,
            credentials: 'same-origin',
        };
        fetch('/create_machine_channel?channel_id='+this.state.selectChannel+'&machine_id='+this.state.selectMachine,options)
            .then(response => response.json())
            .then(response => {
                if (response.message == "Relationship was successfully created.") {
                    this.setState({message: response.message, colorFont: 'green'}, ()=>console.log(""));
                    //return response.json();
                } else {
                    throw new Error(response.message);
                }
            })
            .catch(
                error => {this.setState({message: error.toString(), colorFont: 'red'})}
                );
    }


    deleteRelationship(e){
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append('X-CSRF-Token', Rails.csrfToken());

        const options = {
            method: 'DELETE',
            headers: myHeaders,
            credentials: 'same-origin',
        };
        const url='/delete_machine_channel?channel_id=';
        fetch(url+this.state.selectChannel+'&machine_id='+this.state.selectMachine,options)
            .then(response => response.json())
            .then( response => {
                if (response.message == "Relationship was successfully deleted.") {
                    this.setState({message: response.message, colorFont: 'green'}, ()=>console.log(""));
                    //return response.json();
                } else {
                    throw new Error(response.message);
                }
            })
            .catch(
                error => {this.setState({message: error.toString(), colorFont: 'red'})}
            );
    }



    render () {

        var buttonSend = <button className="btn btn-primary disabled" disabled> Create </button>;
        if( this.state.selectChannel != '' ){
            buttonSend = <button className="btn btn-primary" type={"submit"}> Create </button>
        }

        var buttonDelete = <button className="btn btn-danger disabled" disabled> Delete </button>;
        if( this.state.selectChannel != '' ){
            buttonDelete = <button className="btn btn-danger" onClick={(e) => this.deleteRelationship(e)}> Delete </button>
        }


        var machines = this.props.machines.map( (machine) => {
            return <option value={machine.id} key={machine.id}>{machine.id}: {machine.name}</option>
        });

        var channels = this.props.channels.map( (channel) => {
            return <option value={channel.id} key={channel.id}>{channel.id}: {channel.name}</option>
        });


        const styleLoading = {
            flaot: 'center',
            textAlign: 'center',
        };
        const stylePaddingTop = {
            paddingTop: '10px'
        };
        const styleMessage={
            color: this.state.colorFont,
            fontSize: '18px',
            verticalAlign: 'middle'
        };

        const styleRightItem={
            float: 'right',
            paddingLeft: 'unset',
            alignItems: 'right'
        };

        var label_message =
            <div className="row" style={stylePaddingTop}>
                <div className="col" style={styleLoading}>
                    <span style={styleMessage}>{this.state.message}</span>
                </div>
            </div>;

            

        return (
            <section>
                {label_message}
                <form onSubmit={(e) => this.createRelationship(e)}>

                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <label htmlFor="machine" className="control-label">Choose a machine</label>
                            <select className="form-control" name="machine_id" id="machine_id" defaultValue={this.state.selectMachine} onChange={this.selectedMachine.bind(this)}>
                                <option value=""  disabled hidden>Choose machine</option>
                                {machines}
                            </select>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <br/>

                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                            <label htmlFor="channel_id" className="control-label">Choose a Channel</label>
                            <select className="form-control" name="channel_id" id="channel_id" onChange={this.selectedChannel.bind(this)} defaultValue={this.state.selectChannel}>
                                <option value=""  disabled hidden>Choose channel</option>
                                {channels}
                            </select>

                        </div>
                        <div className="col-md-2"></div>
                    </div>

    
                    <br/>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            {buttonSend}
                        </div>
                        <div className="col-md-4" >
                            <div className={"hidden"} style={styleRightItem}>{buttonDelete}</div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </form>
            </section>
        )
    }
}

export default RelationshipsMachineChannel