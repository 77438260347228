import React from 'react'

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            valueTextarea: '',
            message: '',
            colorFont: 'green',
            loading: false
        };

        this.sendEmailHandle = this.sendEmailHandle.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this)

    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextState.valueTextarea != this.state.valueTextarea)
            return true;
        if(nextState.message != this.state.message)
            return true;
        if(nextState.loading != this.state.loading)
            return true;
        return false
    }


    handleChangeEmail(e){
        //console.log("change email e: ",e.target.value);
        this.setState({valueTextarea: e.target.value, message: ''})
    }

    sendEmailHandle(e){
        this.setState({loading: true});
        //console.log("e: ",e);
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append('X-CSRF-Token', Rails.csrfToken());

        //console.log("Contenuto",e.target[0].value);
        const options = {
            method: 'POST',
            headers: myHeaders,
            credentials: 'same-origin'
        };

        var url="/send_emails?mex="+this.state.valueTextarea;
        fetch(url,options)
            .then(response => {
                //console.log("Response: ",response);
                if (response.ok) {
                    this.setState({message: "Email sent correctly!", colorFont: 'green', loading: false, valueTextarea: ''}, ()=>console.log(""));
                    //return response.json();
                } else {
                    throw new Error("Error is occurred, please retry in another time.");
                }
            })
            .catch(
                error => {this.setState({message: error.toString(), colorFont: 'red', loading: false}, ()=> console.log(""))}
                );
    }


    render () {

        var buttonSend = <button className="btn btn-primary disabled" disabled> Send </button>;
        if( this.state.valueTextarea!=''){
            buttonSend = <button className="btn btn-primary" type={"submit"}> Send </button>
        }

        const styleLoading = {
            flaot: 'center',
            textAlign: 'center',
        };
        const styleSpan = {
            paddingLeft: '10px',
            fontSize: '20px',
            verticalAlign: 'middle'
        };
        const stylePaddingTop = {
            paddingTop: '10px'
        };
        const styleMessage={
            color: this.state.colorFont,
            fontSize: '18px',
            verticalAlign: 'middle'
        };

        var label_message =
            <div className="row" style={stylePaddingTop}>
                <div className="col" style={styleLoading}>
                    <span style={styleMessage}>{this.state.message}</span>
                </div>
            </div>;

        var loading = <div/>;
        if(this.state.loading)
            loading =   <div className="row" style={stylePaddingTop}>
                            <div className="col" style={styleLoading}>
                                <img src="/assets/loading.gif" alt="Loading" width="25" height="25"></img>
                                <span style={styleSpan}>Wait a moment</span>
                            </div>
                        </div>;

        return (
            <section>
                {label_message}
                {loading}
                <form onSubmit={(e) => this.sendEmailHandle(e)}>
                    <div className="modal-body">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="far fa-fw fa-envelope"></i></span>
                            <textarea value={this.state.valueTextarea} className="form-control" placeholder="Write email here"
                                      onChange={(e)=>this.handleChangeEmail(e)}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        {buttonSend}
                    </div>
                </form>
            </section>
        )
    }
}

export default Contact