import React from 'react'
import Refill from './Refill'

class Refills extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            machineName: '',
            //BUTTONS - STEP -> batch len
            first: 0,
            last: 0, //da aggiornare in len della lista di refills
            current: 0,
            lastBatch: 0,
            refillsBatch: [],
            step: 20
        };
        //console.log("props di refills_: ",this.props);

        this.getMachineName= this.getMachineName.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getMachineName();
    }

    componentDidUpdate(propsPrecedenti, statePrecedente, snapshot){
        if (propsPrecedenti != this.props){
            //last batch. ES: 170/20 = 8.5 -> 8 è l'ultimo batch che va da 160 all'ultimo
            var last_batch = Math.floor(this.props.refills.length/this.state.step); 
            var end = this.state.step;
            var propsRefills = this.props.refills;
            if (propsRefills.length < this.state.step){
                end = propsRefills.length;
            }
            this.setState({last: propsRefills.length, refillsBatch: propsRefills.slice(0, end), lastBatch: last_batch});
        }       
    }



    getMachineName(){
/*        const api='http://lnx01bchn.carpigianigroup.com:10006/api/carpigiani-corda/me';
        fetch(api)
            .then(results => results.json())
            .then(results => this.setState({machineName: results.me}, function () {
                console.log()
            }))
            .catch((e) => console.log(e))
*/      this.setState({machineName: "Carpigiani's Blockchain refills"})
    }



    //================================ BUTTONS HANDLE ==========================

    onChangePageToFirst() {
        var step = this.state.step;
        var end = step;
        var propsRefills = this.props.refills;
        if (propsRefills.length < step){
            end = propsRefills.length;
        }
        this.setState({refillsBatch: this.props.refills.slice(0,end), current: 0}, () => this.forceUpdate());
    }

    onChangePageToLast() {
        var start = 0;
        var step = this.state.step;
        var propsRefills = this.props.refills;
        var cur = this.state.lastBatch; //torna intero
        if (propsRefills.length > step){
            //ottengo last current * step = start ultimo batch
            start = cur*step; 
        }
        this.setState({refillsBatch: this.props.refills.slice(start,this.props.refills.length), 
                    current: cur}, () => this.forceUpdate());
    }

    onChangePageToPrev() {
        // 1 < prev < len mod STEP
        var prev = this.state.current-1;
        var start = (prev)*this.state.step;
        var end = start + this.state.step;
        this.setState({refillsBatch: this.props.refills.slice(start,end), current: prev}, () => this.forceUpdate());
    }

    onChangePageToNext() {
        // 1 < next < len mod STEP
        var next = this.state.current+1;
        var start = (next)*this.state.step;
        var end = start + this.state.step;
        this.setState({refillsBatch: this.props.refills.slice(start,end), current: next}, () => this.forceUpdate());
    }



    render () {
        //console.log("refillsBarch:",this.state.refillsBatch);
        const centerItems = {
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
        };

        var refills=null;
        var totLiters=0;
        var totalRefills = this.props.refills.length;

        //ordino secondo il timestamp dal più recente (cima) al più vecchio (coda)
        if(totalRefills>0){
            this.props.refills.sort(function(a,b) {
                return b.Value.refillTimestamp - a.Value.refillTimestamp
            });
            refills = this.props.refills.map((refill, index) => {
                if (refill.Value.quantity.length > 0){
                    totLiters += parseInt(refill.Value.quantity);
                }
                if (this.state.refillsBatch.includes(refill)){
                    //console.log("DAJE");
                    return (
                        <Refill data={refill.Value} key={index}/>
                    )
                }
            });
        }
        else{
            refills=<div/>;
        }

        var message;
        if(this.props.msg!=""){
            message =   
                    <section>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className={"alert alert-warning"} style={centerItems}>{this.props.msg}</div>
                            </div>
                        </div>
                        <br/>
                    </section>;
        }
        else {
            message=<div/>;
        }
        


        //BUTTONS PER SCORRERE I REFILLS (BATCHSIZE = STEP)
        var next = this.state.current+1;
        var prev = this.state.current-1;
        var buttonFirst=(
            <button className='btn btn-primary is-rounded btn-sm is-info' onClick={this.onChangePageToFirst.bind(this)} id={"avoidPdf"}>
                    <span> First </span> 
            </button>);

        var buttonLast=(
            <button className='btn btn-primary is-rounded btn-sm is-info' onClick={this.onChangePageToLast.bind(this)} id={"avoidPdf"}>
                    <span> Last </span> 
            </button>);

        
        var class_name_next = 'btn btn-primary is-rounded btn-sm is-info ';
        var disabled_next = false;
        if (next > this.state.lastBatch){ 
            class_name_next += 'disabled';
            disabled_next = true;
        }
        var buttonNext=(
            <button className={class_name_next} disabled={disabled_next} onClick={this.onChangePageToNext.bind(this)} id={"avoidPdf"}>
                    <span> Next </span> 
            </button>
        );


        var class_name_prev = 'btn btn-primary is-rounded btn-sm is-info ';
        var disabled_prev = false;
        if (prev < 0){ 
            class_name_prev += 'disabled';
            disabled_prev = true;
        }
        var buttonPrev=(
            <button className={class_name_prev} disabled={disabled_prev} onClick={this.onChangePageToPrev.bind(this)} id={"avoidPdf"}>
                    <span> Prev </span> 
            </button>
        );

        var buttonCurrentDisabled = (
            <button className='btn btn-primary is-rounded btn-sm is-info disabled' disabled={true} onClick={this.onChangePageToPrev.bind(this)} id={"avoidPdf"}>
                <span> {this.state.current} </span> 
            </button>
        )

        var rangeDate = "[ N/A | N/A ]";
        var today = new Date();
        if (this.props.startDate.toLocaleDateString("it-IT") != today.toLocaleDateString("it-IT")){
            rangeDate = "[ "+this.props.startDate.toLocaleDateString("it-IT")+" | "+this.props.endDate.toLocaleDateString("it-IT")+" ]";
        }


        return (
            <section>
                <nav className="navbar navbar-default">
                    <div className="container-fluid" style={centerItems}>
                        <div className="navbar-header">
                            <div  className="navbar-brand" >{this.state.machineName}</div>
                        </div>
                    </div>
                </nav>

                <div className="row" >
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <div className="panel-group" id="accordion">
                            <div className="panel">
                                <div className="panel-primary">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            Recorded refills on period {rangeDate}: Total fill liters [{Math.round(((totLiters/1000) + Number.EPSILON)*10)/10} lt] - total refill items [{totalRefills}]
                                        </h4>
                                    </div>
                                </div>

                                {message}


                                {refills}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                {buttonFirst} || {buttonPrev} || {buttonCurrentDisabled} || {buttonNext} || {buttonLast}  
            </section>
        )
    }
}

export default Refills