import React from "react"
import FabricIndexTabPanelEndpoint from './FabricIndexTabPanelEndpoint'
import {getSuggestions, renderSuggestion, getSuggestionValue} from "./AutoSuggest"
import Autosuggest from 'react-autosuggest'


class FabricIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            suggestions: [],
            autoSuggestNames: this.props.channelsName, 
            suggestCurrentValue: "",
            endpoints: [],
            msg: ''
        };

        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onChangeSuggest = this.onChangeSuggest.bind(this);
    }




    getFabricChannel(){
        if (!this.state.autoSuggestNames.some(item => this.state.suggestCurrentValue === item)){
            this.setState({msg: 'Channel not exists', endpoints: []},() => this.forceUpdate());
        }
        else{
            var  myHeaders = new Headers();
            myHeaders.append('X-CSRF-Token', Rails.csrfToken());
            myHeaders.append('Content-Type', 'application/json');
    
            const options = {
                method: 'POST',
                headers: myHeaders,
                credentials: 'same-origin',
                body: JSON.stringify({'fabric': 
                    {"channel_name": this.state.suggestCurrentValue}
                })
            };
    
            const request = new Request('/fabric/search_channel_endpoins', options);
    
            fetch(request)
                .then(results => results.json())
                .then(results => { 
                    if (results.message != "" && results.message != null){
                    this.setState({msg: results.message, endpoints: []}, () => this.forceUpdate());
                    }
                    else if(results.length > 0) {
                        this.setState({
                            msg: "",
                            endpoints: results
                        }, function () {
                            this.forceUpdate();
                        });
                    }
                    else {
                        this.setState({msg: "WARNING: No endpoints are up.", endpoints: []}, () => this.forceUpdate());
                    }
                })
                .catch((error) => console.error(error))
        }
    }



    // ---------------------------- Autosuggest ---------------------------- 
    onChangeSuggest(event, { newValue }){
        this.setState({
            suggestCurrentValue: newValue
        }, () => this.getFabricChannel);
    };

    onSuggestionsFetchRequested({ value }){
        this.setState({
            suggestions: getSuggestions(value, this.state.autoSuggestNames)
        });
    };

    onSuggestionsClearRequested(){
        this.setState({
            suggestions: []
        });
    };




    render () {

        const suggestions = this.state.suggestions;
        var  endpoints = this.state.endpoints.map( (endpoint, index) =>{
            return ( 
                <div key={index}>
                    <FabricIndexTabPanelEndpoint endpoint={endpoint} /> 
                </div>
            )
        });

        var  message;
        if(this.state.msg!=""){
            message =   <div className="panel panel-default">
                            <div className="panel-body">
                                <div className={"alert alert-warning"} style={centerItems}>{this.state.msg}</div>
                            </div>
                        </div>;
        }
        else {
            message="";
        }

        const inputProps = {
            placeholder:"Search channel (car...)",
            value: this.state.suggestCurrentValue,
            onChange: this.onChangeSuggest,
            type: "search",
            pattern: "[a-z0-9-]*",
            title: "Sono vietati i caratteri speciali. Inserire prima 'car'.",
        };

        const centerItems = {
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
        };
        const stylePaddingUp={
            paddingTop: "22px",
            float: 'right',
        };

        return (
            <section>

                <div className="row" style={centerItems}>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-sm-9">
                                <label htmlFor="channels" className="control-label">Choose a channel</label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={ inputProps }
                                />
                            </div>
                            <div className="col-sm-1" ></div>
                            <div className="col-sm-2" >
                                <div  style={stylePaddingUp}>
                                    <button className='btn btn-info is-rounded btn-md ' onClick={this.getFabricChannel.bind(this)}>Search</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <br/>

                {message}

                <br/>

                {endpoints}

            </section>
        )

    }
}

export default FabricIndex
